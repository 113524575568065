import(/* webpackMode: "eager", webpackExports: ["LoginPageContent"] */ "/home/runner/_work/42next-app-client/42next-app-client/apps/auth/src/app/[locale]/[method]/login/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionChecker"] */ "/home/runner/_work/42next-app-client/42next-app-client/apps/auth/src/app/[locale]/[method]/login/session-checker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/translations.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useClientLocale"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-locale.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useClientTranslations"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-translation.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CountriesProvider","useCountriesProvider"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/utils/src/countries/use-countries-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1._kcfdar7ejhuq4frnlc337k3y7y/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1._kcfdar7ejhuq4frnlc337k3y7y/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
